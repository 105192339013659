// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/whos-here.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/whos-here.tsx");
  import.meta.hot.lastModified = "1697025053667.373";
}
// REMIX HMR END

import usePartySocket from "partysocket/react";
import { useState } from "react";
import countryCodeEmoji from "./country-code-emoji";

// This is a component that will connect to the partykit backend
// and display the number of connected users, and where they're from.
export default function WhosHere(props) {
  _s();
  const [users, setUsers] = useState();
  usePartySocket({
    host: props.host,
    // connect to the party defined by 'geo.ts'
    party: "geo",
    // this can be any name, we just picked 'index'
    room: "index",
    onMessage(evt) {
      const data = JSON.parse(evt.data);
      setUsers(data);
    }
  });
  return !users ? "Connecting..." : <div className="presence">
      <b>Who's here?</b>
      <br />
      {users?.total} user{users?.total !== 1 ? "s" : ""} online. (
      {Object.entries(users?.from || {}).map(([from, count]) => {
      return `${count} from ${countryCodeEmoji(from)}`;
    }).join(", ")}
      )
    </div>;
}
_s(WhosHere, "7A+NXZTWAfY23L+pra5cR7ij/nY=", false, function () {
  return [usePartySocket];
});
_c = WhosHere;
var _c;
$RefreshReg$(_c, "WhosHere");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;